import { notFound } from 'next/navigation';
import apiClient from '@/apiClient';
import {
  DTO, DTOSearch, DTOServices,
} from '@/lib/types/interfaces/DTO';
import { CategorySeo } from '@/lib/types/interfaces/CategorySeo';
import { LocationSearch } from '@/lib/types/interfaces/LocationSearch';
import { Category } from '@/lib/types/interfaces/Category';
import { HireSearchParams } from '@/lib/types/interfaces/HireSearchParams';
import { miles, pageLimit } from '@/constants';
import { arrayToQueryParam } from '@/lib/utils/functions';
import { Vendor } from '@/lib/types/interfaces/Vendor';
import { City } from '@/lib/types/interfaces/City';
import { Linking } from '../types/interfaces/Linking';

export const getCategoriesSeo = async (query?: string) => {
  try {
    return await apiClient.get<DTO<CategorySeo[]>>(`/categories/seo${query || ''}`, { cache: 'no-cache' });
  } catch (e) {
    console.error(e);
  }
};

export const getLocationSearch = async (location?: string) => {
  try {
    return await apiClient.get <DTO<LocationSearch[]>>(
      `/geo/location?search=${location}`,
      { cache: 'no-cache' },
    );
  } catch (e) {
    console.error(e);
  }
};

export const getGeoCity = async (id?: string) => {
  try {
    return await apiClient.get <DTO<City>>(
      `/geo/city?id=${id}`,
      { cache: 'no-cache' },
    );
  } catch (e) {
    console.error(e);
  }
};

export const getRandomListing = async () => {
  try {
    return await apiClient.get <DTOServices<Vendor[]>>(
      '/random',
      { cache: 'no-cache' },
    );
  } catch (e) {
    console.error(e);
  }
};

export const getCategories = async (parentIds?: number[]) => {
  const query = parentIds?.length ? `?${arrayToQueryParam('parent_ids', parentIds || [])}` : '';
  return apiClient.get<DTO<Category[]>>(`/categories/list${query}`);
};

export const getHire = async ({ subCategory, page, ...rest }: HireSearchParams) => {
  let subCategoriesQuery = '';
  if (Array.isArray(subCategory) && subCategory.length) {
    subCategoriesQuery = `&${arrayToQueryParam('subCategory', subCategory)}`;
  }
  if (typeof subCategory === 'string') {
    subCategoriesQuery = `&subCategory[]=${subCategory}`;
  }

  const radiusQuery = rest.loc ? miles.find(mile => mile === rest.radius) || miles[0] : '';
  const { radius, ...filteredRest } = { ...rest, radius: undefined };

  const query = new URLSearchParams({
    ...filteredRest,
    ...(page && { offset: String((Number(page) - 1) * pageLimit) }),
    limit: String(pageLimit),
    ...(radiusQuery ? { radius: radiusQuery } : {}),
  } as Record<string, string>).toString() + subCategoriesQuery;

  try {
    const { data, meta } = await apiClient.get<DTOSearch<Vendor[]>>(`/search?${query}`, { cache: 'no-cache' });

    return {
      data,
      count: meta.total,
    };
  } catch (err) {
    console.error(err);
  }
};

export const getServicePage = async (slug: string) => {
  try {
    return await apiClient.get<DTO<Vendor>>(`/service/${slug}`, { cache: 'no-cache' });
  } catch (e) {
    console.error(e);
    notFound();
  }
};

export const getGeoStates = async () => {
  try {
    return await apiClient.get<DTO<string[]>>('/geo/states');
  } catch (e) {
    console.error(e);
  }
};

export const getServices = async (params: string, slug?: string[], searchParams?: Record<string, string>) => {
  const query = searchParams ? `?${new URLSearchParams({
    ...(searchParams?.page && { offset: String((Number(searchParams.page) - 1) * pageLimit) }),
    limit: String(pageLimit),
  } as Record<string, string>).toString()}` : null;

  try {
    const slugPath = slug?.length ? `/${slug.join('/')}` : '';
    return await apiClient.get<DTOServices<Vendor[]>>(`/services/${params}${slugPath}${query}`);
  } catch (e) {
    console.error(e);
    notFound();
  }
};

export const getLinking = async (params?: string, slug?: string[]) => {
  try {
    const category = params ? `category=${params}` : '';
    const subCategory = slug?.length && slug[0].length !== 2 ? `&subcategory=${slug[0]}` : '';
    const stateIndex = slug?.findIndex(item => item.length === 2);
    let state = '';
    let city = '';

    if (slug?.length && typeof stateIndex !== 'undefined' && stateIndex !== -1) {
      state = `&state=${slug?.[stateIndex]}`;
      city = slug?.[stateIndex + 1] ? `&city=${slug[stateIndex + 1].replaceAll('_', ' ')}` : '';
    }
    const query = `${category}${subCategory}${state}${city}`;

    return await apiClient.get<DTOServices<Linking[]>>(`/linking?${query}`, { cache: 'no-cache' });
  } catch (e) {
    console.error(e);
  }
};
